import React, { useState } from "react";

const CenteredMenu = () => {
  const [isHovered, setIsHovered] = useState(false);
  const inicio = "INICIO >";

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end", // Alinea los elementos al lado derecho
      width: "100%",
      padding: "10px 20px",
      boxSizing: "border-box",
      backgroundColor: "white",
      zIndex: 10,
      position: "sticky",
      top: "0",
    },
    rightContainer: {
      display: "flex",
      alignItems: "center",
      width: "440px",
      marginRight: "3%", // Espaciado desde el borde derecho
    },
    imageRight: {
      height: "75px",
      width: "auto",
      marginLeft: "3%", // Separación entre texto y la imagen
      transition: "all 0.3s ease",
    },
    colorBlack: {
      color: "black",
      fontFamily: '"Lulo Clean W01 One Bold", sans-serif',
      transition: "all 0.3s ease",
      letterSpacing: "0.04em",
      fontSize: "13px",
      paddingTop: "8%",
    },
    // Media queries dinámicas para pantallas pequeñas
    mediaQuery: {
      container: {
        flexDirection: "row", // Cambiar a filas en pantallas pequeñas
        padding: "5px 10px",
        alignItems: "center",
      },
      rightContainer: {
        width: "100%", // Ajustar el ancho para pantallas pequeñas
        marginRight: "0", // Eliminar margen lateral
      },
      imageRight: {
        height: "50px", // Reducir el tamaño de la imagen
        marginLeft: "2%",
      },
      colorBlack: {
        fontSize: "12px", // Reducir tamaño del texto
        paddingTop: "4%", // Ajustar el espaciado superior
      },
    },
  };

  // Aplicar estilos condicionalmente si el ancho de la pantalla es menor a 768px
  const isMobile = window.innerWidth <= 768;
  const appliedStyles = {
    container: isMobile ? { ...styles.container, ...styles.mediaQuery.container } : styles.container,
    rightContainer: isMobile
      ? { ...styles.rightContainer, ...styles.mediaQuery.rightContainer }
      : styles.rightContainer,
    imageRight: isMobile
      ? { ...styles.imageRight, ...styles.mediaQuery.imageRight }
      : styles.imageRight,
    colorBlack: isMobile
      ? { ...styles.colorBlack, ...styles.mediaQuery.colorBlack }
      : styles.colorBlack,
  };

  return (
    <div style={appliedStyles.container}>
      <div style={appliedStyles.rightContainer}>
        <a style={appliedStyles.colorBlack} href="/">
          {inicio}
        </a>
        <img
          src={require(`../img/brandeo.PNG`)}
          alt="Inicio"
          style={appliedStyles.imageRight}
        />
      </div>
    </div>
  );
};

export default CenteredMenu;
