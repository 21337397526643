const clientData = [
  {
    image: require('../img/clientes/ferrero.PNG'),
    image2: require('../img/clientes/ferrero2.PNG'),
    company: 'FERRERO ROCHER DE MEXICO',
    name: 'Juan Pérez',
    percentage: 75,
    link: '/proyectos/empresa-a',
  },
  {
    image: require('../img/clientes/driscol.PNG'),
    image2: require('../img/clientes/driscol2.PNG'),
    company: 'DRISCOLLS',
    name: 'María López',
    percentage: 50,
    link: '/proyectos/empresa-b',
  },
  {
    image: require('../img/clientes/astros.PNG'),
    image2: require('../img/clientes/astros2.PNG'),
    company: 'ASTROS DE JALISCO',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
  {
    image: require('../img/clientes/electrolit.PNG'),
    image2: require('../img/clientes/electrolit2.PNG'),
    company: 'Electrolit',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
  {
    image: require('../img/clientes/brandeo.PNG'),
    image2: require('../img/clientes/brandeo2.PNG'),
    company: 'Brandeo',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
];
export default clientData;