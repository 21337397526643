import React, { useState } from "react";

const CarouselComponent = ({ mainImage, images, descriptions, titleImage, title, workList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAllThumbnails, setShowAllThumbnails] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalDescription, setModalDescription] = useState(""); // Estado para la descripción de la imagen

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleShowAllThumbnails = () => {
    if (images && images.length > 0) {
      setShowAllThumbnails(true);
      setModalImage(images[currentIndex]); // Verifica que images exista y tenga datos
      setModalDescription(descriptions?.[currentIndex] || "Sin descripción disponible"); // Usa una descripción por defecto si no existe
    } else {
      console.error("No hay imágenes para mostrar en el modal.");
    }
  };

  const handleCloseViewer = () => {
    setShowAllThumbnails(false);
  };

  const handleModalImageClick = (img, index) => {
    setModalImage(img);
    setModalDescription(descriptions?.[index] || "Sin descripción disponible"); // Usa una descripción por defecto
  };

  return (
    <div className="carousel-container">
      <div className="content">
        {/* Lado izquierdo */}
        <div className="left-side">
          <img src={titleImage} alt="Title" className="title-image" />
          <h1 className="title lulo">{title}</h1>
          <ul className="work-list curier">
            {workList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        {/* Lado derecho */}
        <div className="right-side">
          <div className="header-bar"></div>
          <div className="carousel">
            <button className="arrow left" onClick={handlePrevious}>
              &#9664;
            </button>
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex}`}
              className="main-image"
            />
            <button className="arrow right" onClick={handleNext}>
              &#9654;
            </button>
          </div>
          <div className="thumbnails">
            {images.slice(0, 3).map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index}`}
                className={`thumbnail ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
            {images.length > 3 && (
              <button
                className="show-all-button"
                onClick={handleShowAllThumbnails}
              >
                <img
                  className="imagenesMore"
                  src={require(`../img/imagenes.png`)}
                  alt="Mas imagenes"
                  title="Ver más imágenes"
                />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Modal para todas las miniaturas */}
      {showAllThumbnails && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={handleCloseViewer}>
              &times;
            </button>
            {/* Imagen grande dentro del modal */}
            <div className="modal-large-image">
              <img src={modalImage} alt="Large view" className="large-image" />
              <p className="modal-description">{modalDescription}</p>
            </div>
            {/* Miniaturas */}
            <div className="all-thumbnails">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Thumbnail ${index}`}
                  className={`thumbnail ${
                    img === modalImage ? "active" : ""
                  }`}
                  onClick={() => handleModalImageClick(img, index)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselComponent;