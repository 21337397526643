import React, { useState, useEffect } from 'react';

const AnimatedRoute = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 50); // Pequeño retraso
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      style={{
        display: 'flex', // Asegura que no se altere el diseño si el padre usa flexbox
        flexDirection: 'column', // Si necesitas respetar la dirección
        width: '100%',
        height: '100%',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 1s ease, transform 1s ease',
      }}
    >
      {children}
    </div>
  );
};

export default AnimatedRoute;
