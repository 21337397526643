import React from 'react';
import VerticalMenu from './componentes/menu';
import Superior from './componentes/superior';
import Izquierda from './componentes/izquierda';
import FooterLine from './componentes/footer';
import Inicio from './componentes/inicio';
import Quienos from './componentes/quienos';
import ClientComponent from './componentes/clienteCoponente';
import clientData from './json/clientData';
import CarouselList from './componentes/carouselList';
import carouselData from './json/carouselData';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AnimatedRoute from './componentes/animatedRoute';
import ContactSection from './componentes/contacto';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Superior />
      <Izquierda />
      <VerticalMenu />
      <Routes>
        <Route
          path="/"
          element={
            <AnimatedRoute>
              <Inicio />
            </AnimatedRoute>
          }
        />
        <Route
          path="/nosotros"
          element={
            <AnimatedRoute>
              <Quienos />
            </AnimatedRoute>
          }
        />
        <Route
          path="/clientes"
          element={
            <AnimatedRoute>
              <ClientComponent data={clientData} />
            </AnimatedRoute>
          }
        />
        <Route
          path="/proyectos"
          element={
            <AnimatedRoute>
              <CarouselList carousels={carouselData} />
            </AnimatedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <AnimatedRoute>
              <ContactSection  />
            </AnimatedRoute>
          }
        />
      </Routes>
      <FooterLine />
    </BrowserRouter>
  );
}

export default App;
