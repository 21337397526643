import React from 'react';

const FooterLine = () => {
  const styles = {
    footer: {
      position: 'fixed',      // Fija el footer en la parte inferior de la ventana
      bottom: 0,
      left: 0,
      width: '100%',          // Abarca todo el ancho de la pantalla
      height: '40px',
      backgroundColor: '#282626',
      textAlign: 'center',    // Opcional: centra el contenido del footer
      color: 'white',         // Opcional: color del texto en el footer
      lineHeight: '30px',     // Centra verticalmente el contenido del footer
    },
  };

  return <div style={styles.footer}></div>;
};

export default FooterLine;
