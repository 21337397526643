import React, { useState, useEffect } from 'react';
import '../css/SplitScreen.css'; // Asegúrate de incluir este archivo CSS

const SplitScreen = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Retraso para asegurarnos de que la clase de animación se aplique correctamente
    const timeout = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`container ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="leftSide" id='textoNosotros'>
        <span className="underline">S</span>obre nosotros
      </div>
      <div className="rightSide">
        <div className="divConten">
          <label className="barlow textArriba">
            Somos Branding, somos gestión, Somos logística, somos propuesta…
          </label>
          <label className="lulo textAbajo">En Brandeo utilizamos</label>
          <label className="lulo textAbajo">el posicionamiento,</label>
          <label className="lulo textAbajo">el propósito y </label>
          <label className="lulo textAbajo">los valores de tu marca </label>
          <label className="lulo textAbajo">para ofrecerte promocionales</label>
          <label className="lulo textAbajo">diseñados a tu medida.</label>
        </div>
      </div>
    </div>
  );
};

export default SplitScreen;
