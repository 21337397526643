import React from "react";
import CarouselComponent from "./carouselComponent";

const CarouselList = ({ carousels }) => {
  return (
    <div className="carousel-list">
      {carousels.map((carousel, index) => (
        <CarouselComponent
          key={index}
          mainImage={carousel.mainImage}
          images={carousel.images}
          titleImage={carousel.titleImage}
          title={carousel.title}
          workList={carousel.workList}
        />
      ))}
    </div>
  );
};

export default CarouselList;
