import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const TwoImagesLeftAligned = () => {
  const [isOverlapping, setIsOverlapping] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation(); // Hook para obtener la ruta actual

  // Detectar si es un dispositivo móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajuste para considerar móviles
    };

    handleResize(); // Verificar tamaño inicial
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const textoPromocionales = document.getElementById('textoPromocionales');
    const textoNosotros = document.getElementById('textoNosotros');

    if (!textoPromocionales || !textoNosotros) return;

    const handleScroll = () => {
      const promRect = textoPromocionales.getBoundingClientRect();
      const nosRect = textoNosotros.getBoundingClientRect();

      const isIntersecting =
        promRect.bottom > nosRect.top &&
        promRect.top < nosRect.bottom &&
        promRect.right > nosRect.left &&
        promRect.left < nosRect.right;

      setIsOverlapping(isIntersecting);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Si es móvil y la ruta es /proyectos, no renderiza el componente
  if (isMobile && location.pathname === '/proyectos') {
    return null;
  }

  const styles = {
    container: {
      position: 'fixed',
      top: '10%',
      left: '7.5%',
      width: '20%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 10,
    },
    textTop: {
      position: 'absolute',
      top: '29%',
      left: '18%',
      fontSize: '36px',
      color: isOverlapping ? 'white' : 'black',
      lineHeight: '1.2em',
      letterSpacing: '0.15em',
      transform: 'rotate(-90deg)',
      transformOrigin: 'bottom left',
    },
    imageBottom: {
      position: 'absolute',
      bottom: '15%',
      left: '4.5%',
      width: '300px',
      display: isOverlapping ? 'none' : 'block',
    },
    imageBottomInvertida: {
      position: 'absolute',
      bottom: '15%',
      left: '4.5%',
      width: '300px',
      display: isOverlapping ? 'block' : 'none',
    },
    textoIzquierda: {
      color: '#686262',
      letterSpacing: '0.35em',
      cursor: 'pointer',
      lineHeight: '1.1em',
      fontSize: '17px',
      marginLeft: '5%',
      width: '450px',
      marginTop: '-7%',
    },
  };

  return (
    <div style={styles.container}>
      <label style={styles.textoIzquierda} className="barlow">
        PERSONALIZADOS A TU MEDIDA
      </label>
      <div id="textoPromocionales" className="anto" style={styles.textTop}>
        PROMOCIONALES
      </div>
      <img
       className='imegenOcultar'
        src={require(`../img/izquierda-img.png`)}
        alt="Imagen Inferior"
        style={styles.imageBottom}
      />
      <img
        className='imegenOcultar'
        src={require(`../img/izquierda-img-invertida.png`)}
        alt="Imagen Inferior"
        style={styles.imageBottomInvertida}
      />
    </div>
  );
};

export default TwoImagesLeftAligned;
