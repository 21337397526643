import React from 'react';

const Quienos = () => {
  const styles = {
    container: {
      width: '100%',
      height: '500px',
      display: 'flex',
      justifyContent: 'center', // Asegura que todo el contenido esté centrado
      alignItems: 'center',    // Centra verticalmente
      backgroundColor: 'white',
      overflow: 'hidden',
      paddingTop: 50,
      boxSizing: 'border-box',
      flexDirection: 'row', // Las imágenes estarán lado a lado por defecto
    },
    imageWrapper: {
      display: 'flex',        // Contenedor para las dos imágenes
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    image: {
      display: 'block',        // Asegura que las imágenes no tengan comportamiento inline
      margin: '0 10px',        // Separa un poco las imágenes
      padding: 0,
      maxHeight: '100%',       // Evita que las imágenes se estiren verticalmente
      width: 'auto',
    },
    // Media queries para hacer el diseño responsivo
    '@media (max-width: 768px)': {
      container: {
        flexDirection: 'column', // Cambia la dirección a columna en pantallas pequeñas
        height: 'auto',          // Deja que la altura sea automática
      },
      imageWrapper: {
        flexDirection: 'column',  // Las imágenes se apilan verticalmente
        alignItems: 'center',     // Centra las imágenes
      },
      image: {
        width: '80%',            // Reduce el tamaño de las imágenes en pantallas pequeñas
        margin: '10px 0',        // Separa las imágenes verticalmente
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.imageWrapper}>
        <img
          src={require('../img/cuadros.PNG')}
          alt="Cuadros"
          style={styles.image}
        />
        <img
          src={require('../img/quienes.PNG')}
          alt="Quienes"
          style={styles.image}
        />
      </div>
    </div>
  );
};

export default Quienos;
