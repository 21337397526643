const carouselData = [
    {
      mainImage: require('../img/clientes/ferrero/SQUISHY.jpg'),
      images: [
        require('../img/clientes/ferrero/1.jpg'),
        require('../img/clientes/ferrero/2.jpg'),
        require('../img/clientes/ferrero/3.jpg'),
        require('../img/clientes/ferrero/4.jpg'),
        require('../img/clientes/ferrero/5.jpg'),
        require('../img/clientes/ferrero/6.jpg'),
        require('../img/clientes/ferrero/7.jpg'),
        require('../img/clientes/ferrero/8.jpg'),
        require('../img/clientes/ferrero/9.jpg'),
      ],
      descriptions: [
        "Descripción de la imagen 1",
        "Descripción de la imagen 2",
        "Descripción de la imagen 3",
        "Descripción de la imagen 4",
        "Descripción de la imagen 5",
        "Descripción de la imagen 6",
        "Descripción de la imagen 7",
        "Descripción de la imagen 8",
        "Descripción de la imagen 9",
      ],
      titleImage: require('../img/clientes/titulos/ferrero.PNG'),
      title: "PROYECTOS",
      workList: ["Memoria usb", "Mochila", "Libreta", "Juego"],
    },
    {
      mainImage: require('../img/clientes/ferrero/SQUISHY.jpg'),
      images: [
        require('../img/clientes/ferrero/1.jpg'),
        require('../img/clientes/ferrero/2.jpg'),
        require('../img/clientes/ferrero/3.jpg'),
        require('../img/clientes/ferrero/4.jpg'),
      ],
      descriptions: [
        "Descripción de la imagen 1",
        "Descripción de la imagen 2",
        "Descripción de la imagen 3",
        "Descripción de la imagen 4",
      ],
      titleImage: require('../img/clientes/titulos/driscoll.PNG'),
      title: "PROYECTOS",
      workList: ["Memoria usb", "Mochila", "Libreta", "Juego"],
    },
    // Agrega más objetos según necesites
  ];
  
  export default carouselData;
  