import React from 'react';

const VideoComponent = () => {
  const hacemos = 'QUÉ HACEMOS >';
  const styles = {
    outerContainer: {
      width: '100%', 
      height: '550px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      overflow: 'hidden',
      bottom: 20,
    },
    videoContainer: {
      position: 'relative', // Necesario para que el texto se posicione relativo al video
      width: '900px', // Controla el tamaño del video y el contenedor
      height: 'auto',
      marginLeft: '5%',
    },
    video: {
      width: '100%',
      height: 'auto',
    },
    overlayText: {
      position: 'absolute',
      bottom: '15%',
      right: '15px',
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: '"Lulo Clean W01 One Bold", sans-serif',
      color: 'black',
      letterSpacing: '0.04em',
      textDecoration: 'none',
      zIndex: 2,
    },
    colorBlack: {
      color: 'black',
    },
  };

  return (
    <div className='videoCentrar' style={styles.outerContainer}>
      <div style={styles.videoContainer}>
        <video style={styles.video} autoPlay loop muted>
          <source src={require(`../video/centro.mp4`)} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
        <a style={styles.colorBlack} href="/proyectos"><div style={styles.overlayText}>{hacemos}</div></a>
      </div>
    </div>
  );
};

export default VideoComponent;
