import React, { useState } from 'react';

const ClientComponent = ({ data = [] }) => {
  const ver = "VER PROYECTOS >";

  // Estado para rastrear el "hover" de cada elemento
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
    container: {
      width: '60%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      marginLeft: '30%',
      marginBottom: '-5%',
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '400px',
      marginBottom: '20px',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    leftSide: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      transition: 'opacity 0.3s ease-in-out',
    },
    rightSide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      justifyContent: 'center',
      marginTop: '-8%',
    },
    companyNameRow: {
      display: 'flex',
      marginBottom: '30px',
    },
    titleRow: {
      marginBottom: '10px',
      fontSize: '17px',
      paddingBottom: '3px',
      letterSpacing: '0.05em',
      fontWeight: 'bold',
    },
    titleCompany: {
      fontSize: '17px',
      paddingBottom: '3px',
      letterSpacing: '0.05em',
      fontWeight: 'bold',
    },
    Company: {
      fontSize: '16px',
      color: '#686262',
      paddingTop: '5px',
      letterSpacing: '0.12em',
    },
    progressBarContainer: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    progressBar: {
      flex: 1,
      height: '24px',
      backgroundColor: '#eee',
      overflow: 'hidden',
      marginRight: '10px',
    },
    progress: (percentage) => ({
      width: `${percentage}%`,
      height: '100%',
      backgroundColor: 'black',
    }),
    link: {
      color: 'black',
      textDecoration: 'underline',
      fontSize: '16px',
      paddingTop: '20px',
      fontWeight: 'bold',
    },
  };

  return (
    <div style={styles.container} className='contenedorClientes'>
      <div style={styles.title}>
        <img src={require(`../img/cliente.PNG`)} alt="Título" />
      </div>
      {data.map((item, index) => (
        <div style={styles.item} key={index}>
          <div
            style={styles.leftSide}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <a href="/proyectos">
            <img
              src={hoveredIndex === index ? item.image2 : item.image}
              alt={item.name}
              style={styles.image}
            />
            </a>
          </div>
          <div style={styles.rightSide}>
            <div style={styles.companyNameRow}>
              <span className="lulo" style={styles.titleCompany}>
                Empresa:
              </span>
              <span className="barlow" style={styles.Company}>
                {item.company}
              </span>
            </div>
            <div className="lulo" style={styles.titleRow}>
              PARTICIPACIÓN DE COMPRA:
            </div>
            <div style={styles.progressBarContainer}>
              <div style={styles.progressBar}>
                <div style={styles.progress(item.percentage)}></div>
              </div>
              <span>{item.percentage}%</span>
            </div>
            <a className="lulo" href="/proyectos" style={styles.link}>
              {ver}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientComponent;
