import React, { Component } from "react";
import Video from '../componentes/video';
import Nosotros from '../componentes/nosotros';
import Contacto from '../componentes/contacto';

export default class Inicio extends Component {
    render() {
        return (
            <div>
                <Video></Video>
                <Nosotros></Nosotros>
                <Contacto></Contacto>
            </div>
        );
    }
}